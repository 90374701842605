<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton v-if="this.isEdit" :to="{name: 'View Restaurant',params: { id: itemId }}"
          class="mr-2"
          color="info"
          :disabled="submitted"
        >
          <CIcon name="cil-external-link" /> View Restaurant
        </CButton>
        <CButton color="dark" :to="prevRoute">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>

      <div class="d-flex flex-column flex-md-row flex-gap-2" :class="{ 'mb-4': form.email && !form.upload_contract }">
        <div class="flex-grow-1">
          <UnsignedContract
            v-if="form.email && !form.upload_contract"
            :id="Number(itemId)"
            :email="form.email"
          />
        </div>
        <div>
          <CAlert v-if="hasdeliveryService"  color="success" class="d-flex flex-gap-2 align-items-center mb-0 h-100">
            <CIcon name="cil-truck" size="2xl" />
            <div>
              <strong>Delivery Service enabled.</strong>
              <div v-if="!hubsCount" class="d-flex flex-gap-2 align-items-center text-danger font-weight-bold flash-it">
                ⚠ Add the postcode ({{ form.restaurant_postcode }}) to the scope of a hub.
              </div>
            </div>
          </CAlert>
        </div>
      </div>

      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="restaurant-form">
        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <CTab active>
            <template slot="title">
              <CIcon name="cil-task"/> General Information
            </template>

            <div class="d-flex flex-column">

              <!-- country, lang, exclusive -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CSelect
                  class="w-100 mr-2"
                  label="Country:"
                  placeholder="Please select.."
                  :lazy="false"
                  :options="authCountries"
                  :value.sync="$v.form.countryId.$model"
                />

                <CSelect
                  class="w-100 mr-2"
                  label="Language:"
                  placeholder="Please select.."
                  :lazy="false"
                  :value.sync="$v.form.lang.$model"
                  :options="allLanguages"
                  :isValid="checkIfValid('lang')"
                />

                <div class="form-group w-100">
                  <label class="d-block" style="font-weight: 500">Exclusive:</label>
                  <CSwitch
                    :checked.sync="$v.form.exclusive.$model"
                    labelOn="YES"
                    labelOff="NO"
                    color="success"
                    shape="pill"
                    class="mt-020"
                    size="lg"
                    :lazy="false" />
                </div>
              </div>

              <!-- restaurant_name, restaurant_slug -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Name of Restaurant:"
                  placeholder="Name of Restaurant"
                  :lazy="false"
                  :value.sync="$v.form.restaurant_name.$model"
                  :isValid="checkIfValid('restaurant_name')"
                />
                <CInput
                  class="w-100"
                  label="Slug:"
                  placeholder="Slug"
                  :lazy="false"
                  v-model="restaurantSlug"
                  :value.sync="$v.form.restaurant_slug.$model"
                  :isValid="checkIfValid('restaurant_slug')"
                  :disabled="invoiceCount > 0"
                />
              </div>

              <!-- address section -->
              <section class="address mb-3">
                <!-- Verify Address From Google -->
                <CInput
                  id="verify_address"
                  :value.sync="verified_address"
                  class="w-100"
                  label="Verify Address From Google:"
                  placeholder="Enter a location"
                  :lazy="false"
                >
                  <template #prepend>
                    <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
                  </template>
                </CInput>

                <!-- restaurant_address -->
                <div class="d-flex flex-column flex-md-row">
                  <CTextarea
                    class="w-100"
                    rows="2"
                    label="Address:"
                    placeholder="Street name and house number"
                    :lazy="false"
                    :value.sync="$v.form.restaurant_address.$model"
                    :isValid="checkIfValid('restaurant_address')"
                  />
                </div>

                <!-- restaurant_postcode, restaurant_city -->
                <div class="d-flex flex-column flex-md-row">
                  <CInput
                    id="PostalCodeNum"
                    type="number"
                    class="w-100 mr-2"
                    label="Postal Code:"
                    placeholder="Zip or Postal Code"
                    :lazy="false"
                    :value.sync="$v.form.restaurant_postcode.$model"
                    :isValid="checkIfValid('restaurant_postcode')"
                  />
                  <CInput
                    class="w-100 mr-2"
                    label="City:"
                    placeholder="City"
                    :lazy="false"
                    :value.sync="$v.form.restaurant_city.$model"
                    :isValid="checkIfValid('restaurant_city')"
                  />

                  <CInput
                    readonly
                    class="w-100 mr-2"
                    label="Latitude:"
                    placeholder="Latitude"
                    :lazy="false"
                    :value.sync="$v.form.resto_lat.$model"
                  >
                    <template #prepend>
                      <CLoadingButton
                        :loading="loading"
                        :color="(isCoordinateUptodate && !!form.resto_lat && !!form.resto_lat) ? 'dark' : 'warning'"
                        :disabled="isCoordinateUptodate && !!form.resto_lat && !!form.resto_lat"
                        :onChange="getCoordinateFromAddress"
                      >
                        <CIcon name="cil-sync"/>
                      </CLoadingButton>
                    </template>
                  </CInput>

                  <CInput
                    readonly
                    class="w-100"
                    label="Longitude:"
                    placeholder="Longitude"
                    :lazy="false"
                    :value.sync="$v.form.resto_lng.$model"
                  />
                </div>
              </section>

              <!-- restaurant_tel, restaurant_gsm -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Phone Number:"
                  placeholder="Restaurant phone number"
                  :lazy="false"
                  :value.sync="$v.form.restaurant_tel.$model"
                />
                <CInput
                  class="w-100"
                  label="Mobile Number:"
                  placeholder="Restaurant mobile number"
                  :lazy="false"
                  :value.sync="$v.form.restaurant_gsm.$model"
                />
              </div>

              <!-- website, website_etenonline_be -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Website:"
                  placeholder="Website"
                  :lazy="false"
                  :value.sync="$v.form.website.$model"
                >
                  <template #append>
                    <CLink
                      class="btn btn-info"
                      role="button"
                      target="_blank"
                      :href="$v.form.website.$model | addUriScheme"
                    >
                      <CIcon name="cil-external-link"/>
                    </CLink>
                  </template>
                </CInput>

                <CInput
                  class="w-100"
                  :label="`Website ${appTitle}:`"
                  :placeholder="`Website ${appTitle}`"
                  :lazy="false"
                  :value.sync="$v.form.website_etenonline_be.$model"
                >
                  <template #append>
                    <CLink
                      class="btn btn-info"
                      role="button"
                      target="_blank"
                      :href="$v.form.website_etenonline_be.$model | addUriScheme"
                    >
                      <CIcon name="cil-external-link"/>
                    </CLink>
                  </template>
                </CInput>
              </div>

              <!-- email, facebookpage -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2 email"
                  label="Email:"
                  placeholder="Email"
                  :lazy="false"
                  :value.sync="$v.form.email.$model"
                  :isValid="checkIfValid('email')"
                />

                <CInput
                  class="w-100"
                  label="Facebook Page:"
                  placeholder="Facebook page"
                  :lazy="false"
                  :value.sync="$v.form.facebookpage.$model"
                >
                  <template #append>
                    <CLink
                      class="btn btn-info"
                      role="button"
                      target="_blank"
                      :href="$v.form.facebookpage.$model ? 'https://www.facebook.com/' + $v.form.facebookpage.$model : null"
                    >
                      <CIcon name="cil-external-link"/>
                    </CLink>
                  </template>
                </CInput>
              </div>

              <!-- slogan, note_public -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Motto:"
                  placeholder="Motto"
                  :lazy="false"
                  :value.sync="$v.form.slogan.$model"
                />

                <CInput
                  class="w-100"
                  label="Public note above menu:"
                  placeholder="Public note above menu"
                  :lazy="false"
                  :value.sync="$v.form.note_public.$model"
                />
              </div>

              <!-- restaurant_description -->
              <div class="d-flex flex-column flex-md-row">

                <div class="form-group w-100 mb-0">
                  <label class="d-block" style="font-weight: 500;">More information for the website:</label>
                  <quill-editor
                    style="min-height: 200px;"
                    v-model="$v.form.restaurant_description.$model"
                    :options="{ placeholder: 'More information for the website' }"
                    :disabled="quill_disabled"
                  />
                </div>

                <!-- <CTextarea
                  class="w-100"
                  rows="3"
                  label="More Information:"
                  placeholder="More information about the website"
                  :lazy="false"
                  :value.sync="$v.form.restaurant_description.$model"
                /> -->
              </div>

            </div>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-text" /> Types
            </template>

            <LinkedTypes :restaurantId="itemId" :linkedTypes.sync="form.restaurant_types" />
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-spa"/> Logo
            </template>

            <div class="d-flex flex-column">
              <CMedia
                :aside-image-props="{
                  src: this.previewImage,
                  shape: 'rounded',
                  width: '100%',
                  height: '120px'
                }"
              >
                <p class="mb-2 font-weight-bold">Choose a logo file:</p>
                <CInput
                  :value.sync="$v.form.restaurant_logo.$model"
                  type="hidden"
                  class="mb-0"
                />
                <!-- <CInputFile
                  :isValid="checkIfValid('restaurant_logo')"
                  label = "Type Image"
                  placeholder = "Chose an image..."
                  custom
                  @change = "onFileChanged"
                /> -->
                <label for="img" class="btn btn-outline-info mb-0">
                  <CIcon name="cil-file" /> Browse
                </label>

                <CInputFile
                  id="img"
                  :class="!checkIfValid('restaurant_logo') ? 'is-invalid' : ''"
                  style="display: none"
                  @change = "onFileChanged"
                />

                <div v-if="!checkIfValid('restaurant_logo')" class="invalid-feedback">
                  <div class="error" v-if="!$v.form.restaurant_logo.required">
                    <span style="font-size: 1.2rem;">⚠️</span> Please select a logo for the restaurant!
                  </div>
                </div>
              </CMedia>

              <CLink v-if="previewImage"
                class="small mt-2 text-danger"
                @click="()=> { this.selectedImage = this.previewImage = null; this.form.restaurant_logo = ''; }"
              >
                <CIcon name="cil-trash" /> Remove Logo
              </CLink>
            </div>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-clock"/> Opening Time
            </template>

            <div class="row">
              <div class="col-12 col-sm-4">
                <CButton
                  class="px-0"
                  color="link"
                  @click="openingTimesCopyTo"
                >
                  <span v-if="copySucceeded.openingTimesCopyTo"><CIcon name="cil-check-alt" class="mr-1" />Applied!</span>
                  <span v-else><CIcon name="cil-copy" class="mr-1" />Apply Monday to all days</span>
                </CButton>
              </div>
              <div class="col-sm-3 d-none d-sm-block px-sm-0"><h5 class="mb-0">Open from</h5></div>
              <div class="col-sm-3 d-none d-sm-block"><h5 class="mb-0">Open till</h5></div>
            </div>

            <restaurant-times @isValid="(value) => isValidOpeningTime.mo = value" day='Monday' :time.sync="$v.form.open_monday.$model" />
            <restaurant-times @isValid="(value) => isValidOpeningTime.tu = value" day='Tuesday' :time.sync="$v.form.open_tuesday.$model" />
            <restaurant-times @isValid="(value) => isValidOpeningTime.we = value" day='Wednesday' :time.sync="$v.form.open_wednesday.$model" />
            <restaurant-times @isValid="(value) => isValidOpeningTime.th = value" day='Thursday' :time.sync="$v.form.open_thursday.$model" />
            <restaurant-times @isValid="(value) => isValidOpeningTime.fr = value" day='Friday' :time.sync="$v.form.open_friday.$model" />
            <restaurant-times @isValid="(value) => isValidOpeningTime.sa = value" day='Saturday' :time.sync="$v.form.open_saturday.$model" />
            <restaurant-times @isValid="(value) => isValidOpeningTime.su = value" day='Sunday' :time.sync="$v.form.open_sunday.$model" />
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-bike" /> Delivery Time
            </template>

            <div class="row mb-2">
              <div class="col-sm">
                <CLink
                  class="d-block mb-3"
                  color="info"
                  @click="copyFromOpeningTimeToDeliveryTime"
                >
                  <span v-if="copySucceeded.openingTime"><CIcon name="cil-check-alt" /> Copied!</span>
                  <span v-else><CIcon name="cil-clone" class="mr-1" />Copy From Opening Time</span>
                </CLink>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-4">
                <CButton
                  class="px-0"
                  color="link"
                  @click="deliveryTimesCopyTo"
                >
                  <span v-if="copySucceeded.deliveryTimesCopyTo"><CIcon name="cil-check-alt" class="mr-1" />Applied!</span>
                  <span v-else><CIcon name="cil-copy" class="mr-1" />Apply Monday to all days</span>
                </CButton>
              </div>
              <div class="col-sm-3 d-none d-sm-block px-sm-0"><h5 class="mb-0">Open from</h5></div>
              <div class="col-sm-3 d-none d-sm-block"><h5 class="mb-0">Open till</h5></div>
            </div>

            <restaurant-times @isValid="(value) => isValidDeliveryTime.mo = value" day='Monday' :time.sync="$v.form.deliver_monday.$model" />
            <restaurant-times @isValid="(value) => isValidDeliveryTime.tu = value" day='Tuesday' :time.sync="$v.form.deliver_tuesday.$model" />
            <restaurant-times @isValid="(value) => isValidDeliveryTime.we = value" day='Wednesday' :time.sync="$v.form.deliver_wednesday.$model" />
            <restaurant-times @isValid="(value) => isValidDeliveryTime.th = value" day='Thursday' :time.sync="$v.form.deliver_thursday.$model" />
            <restaurant-times @isValid="(value) => isValidDeliveryTime.fr = value" day='Friday' :time.sync="$v.form.deliver_friday.$model" />
            <restaurant-times @isValid="(value) => isValidDeliveryTime.sa = value" day='Saturday' :time.sync="$v.form.deliver_saturday.$model" />
            <restaurant-times @isValid="(value) => isValidDeliveryTime.su = value" day='Sunday' :time.sync="$v.form.deliver_sunday.$model" />
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-location-pin" /> Delivery Zones
            </template>

            <CLink
              @click="mapModal = true"
              color="primary"
              class="mb-2 d-block"
            >
              <CIcon name="cil-location-pin" />
              Show on Map
            </CLink>

            <zip-codes
              ref="resZipCodes"
              :active="activeTab == 5"
              :zip_codes.sync="form.zip_codes"
              :restaurant_zip_code="form.restaurant_postcode"
              :restaurant_country="form.countryId"
              :restaurant_lat="form.resto_lat"
              :restaurant_lng="form.resto_lng"
              :delivery_distances.sync="form.delivery_distances"
            />
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-institution" /> Company Info
            </template>

            <!-- btw_nummer -->
            <div class="mb-0 d-flex flex-column flex-sm-row">
              <CInput
                class="w-100 mr-2"
                label="VAT (BTW) Number:"
                placeholder="VAT (BTW) Number"
                :lazy="false"
                :value.sync="$v.form.btw_nummer.$model"
                :disabled="!!(form.btw_nummer) && invoiceCount > 0"
                @focusout="fixVat"
                :isValid="checkIfValid('btw_nummer')"
              >
                <template #append>
                  <CButton
                    color="info"
                    :disabled="checkSpinner || !$v.form.btw_nummer.$model || !isValidVat(form.btw_nummer)"
                    @click="getCompanyInfo"
                  >
                    <CSpinner size="sm" v-if="checkSpinner" />
                    Get Company Info
                  </CButton>
                </template>
              </CInput>

              <div class="form-group w-100">
                <template v-if="form.btw_nummer && isValidVat(form.btw_nummer)">
                  <label class="d-none d-sm-block">&nbsp;</label>
                  <a :href="`https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?lang=NL&ondernemingsnummer=${form.btw_nummer}`"
                    target="blank"
                    class="btn p-1"
                  >
                    <img src="@/assets/images/logo-kbo.jpg" height="28" />
                  </a>
                </template>
              </div>
            </div>

            <div class="mb-3">
              <CAlert :show="allRestaurantByVAT.length > 0" color="info">
                <CIcon :height="24" name="cil-info" class="mr-2" />
                <strong>Restaurants that have {{ form.btw_nummer }} VAT (BTW) Number:</strong>
                <ul class="pl-3 mt-2 mb-0">
                  <li v-for="restaurant in allRestaurantByVAT" :key="restaurant.value">
                    <CLink :to="{ name: 'Edit Restaurant', params: { id: restaurant.value }}">
                      {{ restaurant.label }}
                    </CLink>
                  </li>
                </ul>
              </CAlert>
            </div>

            <!-- firma_naam, firma_type -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-2"
                label="Company Name:"
                placeholder="Company Name"
                :lazy="false"
                :value.sync="$v.form.firma_naam.$model"
              />
              <CInput
                class="w-100"
                label="Company Type:"
                placeholder="Company Type"
                :lazy="false"
                :value.sync="$v.form.firma_type.$model"
              />
            </div>

            <!-- firm address section -->
            <section class="address mb-3">

              <!-- Copy from restaurant address  -->
              <div class="form-group w-100">
                <CLink
                  color="info"
                  @click="copyFromRestaurantAddress"
                >
                  <span v-if="copySucceeded.restaurantAddress"><CIcon name="cil-check-alt" /> Copied!</span>
                  <span v-else><CIcon name="cil-copy" /> Copy From Restaurant Address</span>
                </CLink>
              </div>

              <!-- Verify Firm Address From Google -->
              <CInput
                id="verify_firm_address"
                :value.sync="verified_firm_address"
                class="w-100"
                label="Verify Company Address From Google:"
                placeholder="Enter a location"
                :lazy="false"
              >
                <template #prepend>
                  <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
                </template>
              </CInput>

              <!-- firma_mz_adres -->
              <div class="d-flex flex-column flex-md-row">
                <CTextarea
                  class="w-100"
                  rows="2"
                  label="Address:"
                  placeholder="Street name and house number"
                  :lazy="false"
                  :value.sync="$v.form.firma_mz_adres.$model"
                />
              </div>

              <!-- firma_mz_postcode, firma_mz_city -->
              <div class="d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Postal Code:"
                  placeholder="Zip or Postal Code"
                  :lazy="false"
                  :value.sync="$v.form.firma_mz_postcode.$model"
                />
                <CInput
                  class="w-100 mr-2"
                  label="City:"
                  placeholder="City"
                  :lazy="false"
                  :value.sync="$v.form.firma_mz_city.$model"
                />
              </div>

            </section>

            <!-- bank_rekeninghouder, bank_naam, bank_rekeningnummer -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-md-2"
                label="Bank Account Holder:"
                placeholder="Bank Account Holder"
                :lazy="false"
                :value.sync="$v.form.bank_rekeninghouder.$model"
              />

              <div class="w-100 d-flex flex-row">
                <CInput
                  class="w-100 mr-2"
                  label="Bank Name:"
                  placeholder="Bank Name"
                  :lazy="false"
                  :value.sync="$v.form.bank_naam.$model"
                />
                <CInput
                  class="w-100"
                  label="Bank Account Number (IBAN):"
                  placeholder="Bank Account Number (IBAN)"
                  :lazy="false"
                  :value.sync="$v.form.bank_rekeningnummer.$model"
                  :isValid="checkIfValid('bank_rekeningnummer')"
                />
              </div>
            </div>

            <!-- eigenaar1_naam, eigenaar1_gsm, eigenaar1_email -->
            <h6 class="border-bottom pb-1">Contact Person 1:</h6>
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-md-2"
                label="Name:"
                placeholder="Name"
                :lazy="false"
                :value.sync="$v.form.eigenaar1_naam.$model"
              />

              <div class="w-100 d-flex flex-row">
                <div class="form-group w-100 mr-2">
                  <label class="d-block">
                    Mobile Phone:
                    <CLink
                      v-c-tooltip="'Copy from Restaurant Mobile Number'"
                      color="info"
                      @click="copyFromRestaurantGsm"
                    >
                      <span v-if="copySucceeded.restaurantGsm"><CIcon name="cil-check-alt" /> Copied!</span>
                      <span v-else><CIcon name="cil-copy" /></span>
                    </CLink>
                  </label>
                  <CInput
                    class="w-100 mr-2"
                    placeholder="Mobile Phone"
                    :lazy="false"
                    :value.sync="$v.form.eigenaar1_gsm.$model"
                  />
                </div>

                <div class="form-group w-100 mr-2">
                  <label class="d-block">
                    Email:
                    <CLink
                      v-c-tooltip="'Copy from Restaurant Email'"
                      color="info"
                      @click="copyFromRestaurantEmail"
                    >
                      <span v-if="copySucceeded.restaurantEmail"><CIcon name="cil-check-alt" /> Copied!</span>
                      <span v-else><CIcon name="cil-copy" /></span>
                    </CLink>
                  </label>
                  <CInput
                    class="w-100 email"
                    placeholder="Email"
                    :lazy="false"
                    :value.sync="$v.form.eigenaar1_email.$model"
                  />
                </div>
              </div>
            </div>

            <!-- eigenaar2_naam, eigenaar2_gsm, eigenaar2_email -->
            <h6 class="border-bottom pb-1">Contact Person 2:</h6>
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-md-2"
                label="Name:"
                placeholder="Name"
                :lazy="false"
                :value.sync="$v.form.eigenaar2_naam.$model"
              />

              <div class="w-100 d-flex flex-row">
                <CInput
                  class="w-100 mr-2"
                  label="Mobile Phone:"
                  placeholder="Mobile Phone"
                  :lazy="false"
                  :value.sync="$v.form.eigenaar2_gsm.$model"
                />
                <CInput
                  class="w-100 email"
                  label="Email:"
                  placeholder="Email"
                  :lazy="false"
                  :value.sync="$v.form.eigenaar2_email.$model"
                />
              </div>
            </div>

            <!-- referenties -->
            <div class="d-flex flex-column flex-md-row">
              <CTextarea
                class="w-100"
                rows="2"
                label="References:"
                placeholder="References"
                :lazy="false"
                :value.sync="$v.form.referenties.$model"
              />
            </div>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-settings" /> Settings
            </template>

             <!-- password, startdate -->
             <div class="mb-3 d-flex flex-column flex-md-row flex-gap-2 border-bottom">
              <CInput
                v-if="isEdit"
                class="w-100"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                :lazy="false"
                :value.sync="$v.form.password.$model"
              >
                <template #append>
                  <CButton class="small" @click="showPassword = !showPassword" color="secondary">
                    {{ showPassword ? 'HIDE' : 'SHOW' }}
                  </CButton>
                </template>
              </CInput>

              <div class="w-100">
                <CInput
                  :disabled="true"
                  type="date"
                  class="w-100"
                  label="Start Date:"
                  :lazy="false"
                  :value.sync="$v.form.startdate.$model"
                  :max="new Date().toISOString().slice(0,10)"
                />
              </div>
            </div>

            <!-- connectiewijze -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <div class="form-group w-100">
                <label class="d-block">Connection Method:</label>
                <CInputRadioGroup
                  name="connectiewijze"
                  :options="[
                    { value: 'box', label: 'MagicBox' },
                    { value: 'soft', label: 'Etenonline Software' },
                    { value: 'mail', label: 'E-Mail' },
                  ]"
                  :checked.sync="$v.form.connectiewijze.$model"
                  custom
                  inline
                />
              </div>
            </div>

            <!-- for MagicBox (boxpaymenttype, boxsn, boxsim, boxrentstartdate) -->
            <div v-if="$v.form.connectiewijze.$model == 'box'" class="mb-3 d-flex flex-column flex-md-row flex-gap-2">
              <CSelect
                class="w-100"
                label="Box Payment Type:"
                placeholder="Please select.."
                :lazy="false"
                :value.sync="$v.form.boxpaymenttype.$model"
                :options="[
                  { value: 'rent', label: 'Rent' },
                  { value: 'buy', label: 'Buy' },
                ]"
              />

              <CInput
                class="w-100"
                label="Serial Number:"
                placeholder="Serial Number"
                :lazy="false"
                :value.sync="$v.form.boxsn.$model"
              />

              <CInput
                class="w-100"
                label="SIM Number:"
                placeholder="SIM Number"
                :lazy="false"
                :value.sync="$v.form.boxsim.$model"
              />

              <CInput
                type="date"
                class="w-100"
                label="Box Rental Date:"
                :lazy="false"
                :value.sync="$v.form.boxrentstartdate.$model"
                v-if="$v.form.boxpaymenttype.$model == 'rent'"
              />
              <div v-else class="w-100 d-none d-lg-block"></div>
            </div>

            <!-- type_commission -->
            <div class="mb-3 d-flex flex-column flex-md-row flex-gap-2">
              <div class="form-group w-100">
                <label class="d-block">Commission Type:</label>
                <CInputRadioGroup
                  name="type_commission"
                  :options="['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']"
                  :checked.sync="$v.form.type_commission.$model"
                  custom
                  inline
                />
              </div>
              <div v-if="hasdeliveryService" class="form-group w-100">
                <label class="d-block">🚚 Delivery Service Commission Type:</label>
                <CInputRadioGroup
                  name="type_commission_ds"
                  :options="['U', 'V', 'W', 'X']"
                  :checked.sync="$v.form.type_commission_ds.$model"
                  custom
                  inline
                />
              </div>
            </div>

            <!-- note_internal -->
            <div class="mb-1 d-flex flex-column flex-md-row">
              <CTextarea
                class="w-100"
                rows="3"
                label="Internal Note:"
                placeholder="Internal Note"
                :lazy="false"
                :value.sync="$v.form.note_internal.$model"
              />
            </div>

            <!-- temp_closed, etc. -->
            <div class="mb-3 d-flex flex-column">
              <div class="form-group w-100 mr-2">
                <label class="d-block border-bottom">Temporarily Closed ?</label>
                <CInputRadioGroup
                  name="temporarily_closed"
                  :options="[
                    { value: null, label: 'No' },
                    { value: 'temp_closed', label: 'Now Closed' },
                    { value: 'temp_closed_from', label: 'Closed From ...' },
                  ]"
                  :checked.sync="temporarily_closed"
                  custom
                  inline
                />
              </div>

              <div v-if="temporarily_closed" class="d-inline-flex flex-wrap" style="gap: 1rem;">
                <CInput
                  v-if="temporarily_closed == 'temp_closed_from'"
                  type="date"
                  label="From What Date:"
                  :lazy="false"
                  :value.sync="$v.form.temp_closed_from_date.$model"
                  :min="new Date().toISOString().slice(0,10)"
                  class="border-right pr-3"
                  :isValid="checkIfValid('temp_closed_from_date')"
                />

                <div class="form-group">
                  <label class="d-block" style="font-weight: 500">Closed Until and Including</label>
                  <CSwitch
                    :checked.sync="$v.form.temp_closed_untill.$model"
                    @update:checked="(value) => {if(!value) form.temp_closed_untill_date = null}"
                    labelOn="YES"
                    labelOff="NO"
                    color="success"
                    shape="pill"
                    class="mt-020"
                    size="lg"
                    :lazy="false"
                  />
                </div>

                <CInput
                  v-if="form.temp_closed_untill == true"
                  type="date"
                  label="Date:"
                  :lazy="false"
                  :value.sync="$v.form.temp_closed_untill_date.$model"
                  :min.sync="$v.form.temp_closed_from_date.$model"
                  :isValid="checkIfValid('temp_closed_untill_date')"
                />
              </div>
            </div>

            <!-- onlytakeaway, etc. -->
            <div class="mb-3 d-flex flex-column">
              <div class="form-group w-100 mr-2">
                <label class="d-block border-bottom">Only Pickup ?</label>
                <CInputRadioGroup
                  name="just_takeaway"
                  :options="[
                    { value: null, label: 'No' },
                    { value: 'onlytakeaway', label: 'Pickup Now' },
                    { value: 'onlypickup_from', label: 'Pickup From ...' },
                  ]"
                  :checked.sync="just_takeaway"
                  custom
                  inline
                />
              </div>

              <div v-if="just_takeaway" class="d-inline-flex flex-wrap" style="gap: 1rem;">
                <CInput
                  v-if="just_takeaway == 'onlypickup_from'"
                  type="date"
                  label="From What Date:"
                  :lazy="false"
                  :value.sync="$v.form.onlypickup_from_date.$model"
                  :min="new Date().toISOString().slice(0,10)"
                  class="border-right pr-3"
                  :isValid="checkIfValid('onlypickup_from_date')"
                />

                <div class="form-group">
                  <label class="d-block" style="font-weight: 500">Pickup Until</label>
                  <CSwitch
                    :checked.sync="$v.form.onlypickup_untill.$model"
                    @update:checked="(value) => {if(!value) form.onlypickup_untill_date = null}"
                    labelOn="YES"
                    labelOff="NO"
                    color="success"
                    shape="pill"
                    class="mt-020"
                    size="lg"
                    :lazy="false"
                  />
                </div>

                <CInput
                  v-if="form.onlypickup_untill == true"
                  type="date"
                  label="Date:"
                  :lazy="false"
                  :value.sync="$v.form.onlypickup_untill_date.$model"
                  :min.sync="$v.form.onlypickup_from_date.$model"
                  :isValid="checkIfValid('onlypickup_untill_date')"
                />
              </div>
            </div>

            <!-- menu_active, use takeout price, relation_active, onlyonlinepay -->
            <div class="mb-2 d-flex flex-column flex-md-row flex-gap-2">
              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Menu Active:</label>
                <CSwitch
                  :checked.sync="$v.form.menu_active.$model"
                  labelOn="YES"
                  labelOff="NO"
                  color="success"
                  shape="pill"
                  class="mt-020"
                  size="lg"
                  :lazy="false"
                />
              </div>

              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Use Takeout Price:</label>
                <CSwitch
                  :checked.sync="$v.form.usetoprice.$model"
                  labelOn="YES"
                  labelOff="NO"
                  color="success"
                  shape="pill"
                  class="mt-020"
                  size="lg"
                  :lazy="false"
                />
              </div>

              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Relationship Active (Contract Signed):</label>
                <CSwitch
                  :disabled="true"
                  :checked.sync="$v.form.relation_active.$model"
                  labelOn="YES"
                  labelOff="NO"
                  color="success"
                  shape="pill"
                  class="mt-020"
                  size="lg"
                  :lazy="false"
                />
              </div>

              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Online Payment Only:</label>
                <div class="d-flex flex-column align-items-center">
                  <CSwitch
                    class="mt-020 align-self-start"
                    :class="{'is-invalid': hasdeliveryService}"
                    :disabled="hasdeliveryService"
                    :checked.sync="$v.form.onlyonlinepay.$model"
                    labelOn="YES"
                    labelOff="NO"
                    color="success"
                    shape="pill"
                    size="lg"
                    :lazy="false"
                  />
                  <div class="invalid-feedback">
                    ⚠ Locked: Restaurant offers delivery service
                  </div>
                </div>
              </div>
            </div>

            <!-- food_preparation_time, -none_active- -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CSelect
                class="w-100"
                label="Food Preparation Time:"
                placeholder="Please select.."
                :value.sync="$v.form.food_preparation_time.$model"
                :options="[
                  { value: 15, label: '15' },
                  { value: 30, label: '30' },
                  { value: 45, label: '45' },
                ]"
              />

              <div class="w-100"></div>
              <div class="w-100"></div>
              <div class="w-100"></div>
              <!--
              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Not Published:</label>
                <CSwitch
                  :checked.sync="$v.form.none_active.$model"
                  labelOn="YES"
                  labelOff="NO"
                  color="success"
                  shape="pill"
                  class="mt-020"
                  size="lg"
                  :lazy="false"
                />
              </div>
              -->
            </div>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-pen-nib" /> Contract
            </template>

            <CRow>
              <!-- Contract -->
              <CCol sm="12">
                <CCard>
                  <CCardHeader>
                    <CIcon name="cil-pen-nib" class="align-top mr-2" />
                    <strong>Contract</strong>
                    <small v-if="selectedContractFile" class="d-sm-inline d-block">
                      (
                      <CLink @click="() => selectedContractFile = null">
                        {{ form.selectedContract }} &#10060;
                      </CLink>
                      )
                    </small>

                    <div class="card-header-actions">
                      <label
                        v-if="contractUploadAllowed"
                        for="contract"
                        role="button"
                        class="text-info mb-0"
                        v-c-tooltip="'Upload Signed Contract'"
                      >
                        <CIcon name="cil-cloud-upload" />
                      </label>

                      <CLink
                        v-if="form.upload_contract"
                        href="#" @click="download('contract')"
                        class="text-success card-header-action"
                        v-c-tooltip="'Download Existing Contract'"
                      >
                        <CIcon name="cil-cloud-download"/>
                      </CLink>
                    </div>
                  </CCardHeader>

                  <CCardBody>
                    <div>
                      <CInput
                        :value.sync="$v.form.selectedContract.$model"
                        type="hidden"
                        class="mb-0"
                      />
                      <CInputFile
                        id="contract"
                        :class="!checkIfValid('selectedContract') ? 'is-invalid' : ''"
                        style="display: none"
                        @change = "onSelectedContractChanged"
                      />
                      <div v-if="!checkIfValid('selectedContract')" class="invalid-feedback">
                        <div class="error border-bottom" v-if="!$v.form.selectedContract.required">
                          Please select a valid file! (*.pdf, *.jpeg, *.jpg, *.png, *.gif)
                        </div>
                      </div>
                    </div>

                    <!-- Generate Contract -->
                    <section v-if="contractGenerateAllowed">
                      <div class="mb-3 d-flex flex-column flex-md-row">
                        <div class="form-group w-100">
                          <label class="d-block">Contract Language:</label>
                          <div class="contract-lang-group">
                            <div v-for="lang in allLanguages" v-bind:key="lang.value" class="form-check form-check-inline">
                              <input class="form-check-input d-none" type="radio" name="lang" :id="`lang_${lang.value}`" :value="lang.value" v-model="formContract.contract_lang">
                              <label class="form-check-label" :for="`lang_${lang.value}`">
                                <CIcon v-if="lang.value == 'en'" class="flag mr-1" :width="21" :height="16" :content="$options.flagSet['cifGb']" />
                                <CIcon v-else class="flag mr-1" :height="14" :content="$options.flagSet['cif' + lang.value.charAt(0).toUpperCase() + lang.value.slice(1)]" />
                                {{ lang.label }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mb-3 d-flex flex-column flex-md-row">
                        <CInput
                          class="w-100 mr-2"
                          label="Name:"
                          placeholder="Name"
                          :lazy="false"
                          :value.sync="formContract.name"
                        />
                        <CInput
                          class="w-100"
                          label="Place:"
                          placeholder="Place"
                          :lazy="false"
                          :value.sync="formContract.place"
                        />
                      </div>

                      <div class="d-flex flex-column flex-md-row">
                        <div class="form-group w-100">
                          <label class="d-block">Signature:</label>
                          <VueSignaturePad
                            id="signature"
                            width="100%"
                            height="200px"
                            ref="signaturePad"
                            :options="{
                              penColor: '#000',
                              onBegin: () => { $refs.signaturePad.resizeCanvas() }
                            }"
                          />
                          <a role="button" class="small" @click="$refs.signaturePad.undoSignature()">
                            <CIcon name="cil-action-undo" /> Undo the Signature
                          </a>
                        </div>

                        <div class="w-100"></div>
                      </div>
                    </section>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>

            <RestaurantContract
              ref="contract"
              :form="form"
              :formContract="formContract"
              :sign="formContract.sign"
              @generated="handleContractGenerationComplete"
            />
          </CTab>
        </CTabs>
      </CForm>

      <CModal v-if="mapModal"
        :show.sync="mapModal"
        :closeOnBackdrop="true"
        :centered="false"
        size="xl"
        color="dark"
        title="Restaurant Delivery Areas"
      >
        <map-restaurant-delivery-areas
          :zip_codes.sync="form.zip_codes"
          :restaurant_name.sync="form.restaurant_name"
          :restaurant_lat.sync="form.resto_lat"
          :restaurant_lng.sync="form.resto_lng"
          @add:zip="(zip) => $refs.resZipCodes.addZipCodes(zip)"
        />

        <template #footer>
          <CButton @click="mapModal = false" color="dark">Close</CButton>
        </template>
      </CModal>

    </CCardBody>

    <CCardFooter class="sticky">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="submitted"
          @click="submit()"
        >
          Submit
        </CButton>

        <div class="ml-auto"></div>
      </div>
    </CCardFooter>

    <!-- <CElementCover :opacity="0.8" v-show="submitted"/> -->
    <mc-spinner :opacity="0.8" v-show="submitted" />
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { helpers, required, requiredIf, minLength, email } from "vuelidate/lib/validators"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { flagSet } from '@coreui/icons'

// *** Text Editors
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

// MC Global Components
import RestaurantTimes from '../../components/RestaurantTimes'
import ZipCodes from '../../components/ZipCodes'

// Import Specific Lodash Functions
import _debounce from 'lodash/debounce';

const validIBAN = (value) => !helpers.req(value) || IBAN.isValid(value)

const first_type_must_have_image = (types) => {
  let typesCopy = [...types];
  const sorted = typesCopy.sort((a,b) => a.index - b.index);
  return !!(sorted[0]?.type_image_path)
}

const hasHouseNumber = (restaurant_address) => {
  var regex = /\d+/g;
  return regex.test(restaurant_address);
}

export default {
  name: 'Restaurant',
  flagSet,
  components: {
    vSelect,
    quillEditor,
    RestaurantTimes,
    ZipCodes,
    LinkedTypes: () => import('./linked-types/LinkedTypes'),
    MapRestaurantDeliveryAreas: () => import('../../components/MapRestaurantDeliveryAreas'),
    RestaurantContract: () => import('@/components/RestaurantContract.vue'),
    UnsignedContract: () => import('@/views/restaurants/sign-contract/UnsignedContract.vue'),
  },
  data() {
    return {
      activeTab: 0,
      form: {},
      formContract: {
        contract_lang: 'en',
        name: null,
        place: null,
        sign: null
      },

      itemName: '',
      hasdeliveryService: false,
      hubsCount: 0,
      oldBtw: '',

      isEdit: false,
      submitted: false,
      loading: false,
      checkSpinner: false,

      // For files
      previewImage: undefined,
      selectedImage: undefined,
      selectedContractFile: undefined,

      allLanguages: [],
      allRestaurantByVAT: [],

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // For Restaurant Settings
      temporarily_closed: null,
      just_takeaway: null,

      // Disable auto focus when quill render
      quill_disabled: true,

      // Açılış ve teslimat zamanı doğrulaması için
      isValidOpeningTime: {'mo': true, 'tu': true, 'we': true, 'th': true, 'fr': true, 'sa': true, 'su': true, },
      isValidDeliveryTime: {'mo': true, 'tu': true, 'we': true, 'th': true, 'fr': true, 'sa': true, 'su': true, },

      // Form değişikliklerini izlemek için
      unsavedChanges: false,

      // Google Maps API Autocomplete
      verified_address: '',
      autocomplete_address: null,
      verified_firm_address: '',
      autocomplete_firm_address: null,
      isCoordinateUptodate: false,

      // Show Maps
      mapModal: false,

      // Copy To
      copySucceeded: {
        openingTime: null,
        restaurantAddress: null,
        openingTimesCopyTo: null,
        deliveryTimesCopyTo: null,
        restaurantGsm: null,
        restaurantEmail: null
      },

      // Vuelidate-error-extractor
      attributes: {
        countryId: 'Country',
        lang: 'Language of the restaurant',
        restaurant_name: 'Restaurant name',
        restaurant_slug: 'Restaurant slug',
        restaurant_types: 'Restaurant type',
        restaurant_address: 'Restaurant address',
        restaurant_postcode: 'Restaurant postal code',
        restaurant_city: 'Restaurant city',
        email: 'Restaurant email',

        restaurant_logo: 'Restaurant logo',

        btw_nummer: 'VAT (BTW) Number',
        bank_rekeningnummer: 'Bank Account Number (IBAN)',
        eigenaar1_email: 'Contact Person 1 email',
        eigenaar2_email: 'Contact Person 2 email',

        temp_closed_untill_date: 'Temporarily Closed "Until Date"',
        temp_closed_from_date: 'Temporarily Closed "From Date"',
        onlypickup_untill_date: 'Only Pickup "Until Date"',
        onlypickup_from_date: 'Only Pickup "From Date"',
      },

      // Get previous page
      prevRoute: null,

      // Invoice Count
      invoiceCount : 0,

      showPassword: false,
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      // Tab 0 (General Information)
      countryId: { required },
      lang: { required },
      exclusive: {},
      restaurant_name: { required, minLength: minLength(3) },
      restaurant_slug: { required, minLength: minLength(3) },
      restaurant_types: { required, first_type_must_have_image },
      restaurant_address: { required, hasHouseNumber },
      restaurant_postcode: { required },
      restaurant_city: { required },
      resto_lat: {},
      resto_lng: {},
      restaurant_tel: {},
      restaurant_gsm: {},
      website: {},
      website_etenonline_be: {},
      // email: { required, email },
      email: {
        required,
        email: function () {
          if (!this.form.email) {
            return false;
          }
          const emails = this.form.email.split(";");
          return emails.map(email).reduce((res, val) => res && val, true);
        },
      },
      facebookpage: {},
      slogan: {},
      note_public: {},
      restaurant_description: {},

      // Tab 1 (Logo)
      restaurant_logo: {
        required: (fileName) => {
          if(!fileName) // Logo ekleme zorunlu değil
            return true;

          var filename_arr = fileName.split(".");
          var file_ext = filename_arr[filename_arr.length - 1];

          return ["jpeg", "jpg", "png", "gif"].indexOf(file_ext) !== -1;
        }
      },

      // Tab 2 (Opening Time)
      open_monday: {},
      open_tuesday: {},
      open_wednesday: {},
      open_thursday: {},
      open_friday: {},
      open_saturday: {},
      open_sunday: {},

      // Tab 3 (Delivery Time)
      deliver_monday: {},
      deliver_tuesday: {},
      deliver_wednesday: {},
      deliver_thursday: {},
      deliver_friday: {},
      deliver_saturday: {},
      deliver_sunday: {},

      // Tab 5 (Company Info)
      btw_nummer: {
        checkVAT: function (value) {
          return this.isValidVat(value);
        },
      },
      firma_naam: {},
      firma_type: {},
      firma_mz_adres: {},
      firma_mz_postcode: {},
      firma_mz_city: {},
      bank_naam: {},
      bank_rekeningnummer: { validIBAN },
      bank_rekeninghouder: {},
      eigenaar1_naam: {},
      eigenaar1_gsm: {},
      // eigenaar1_email: { email },
      eigenaar1_email: {
        email: function () {
          if (!this.form.eigenaar1_email) {
            return true;
          }
          const emails = this.form.eigenaar1_email.split(";");
          return emails.map(email).reduce((res, val) => res && val, true);
        },
      },
      eigenaar2_naam: {},
      eigenaar2_gsm: {},
      // eigenaar2_email: { email },
      eigenaar2_email: {
        email: function () {
          if (!this.form.eigenaar2_email) {
            return true;
          }
          const emails = this.form.eigenaar2_email.split(";");
          return emails.map(email).reduce((res, val) => res && val, true);
        },
      },
      referenties: {},

      // Tab 6 (Settings)
      password: {},
      connectiewijze: {},
      boxpaymenttype: {},
      boxsn: {},
      boxsim: {},
      boxrentstartdate: {},

      type_commission: {},
      type_commission_ds: {},
      note_internal: {},
      menu_active: {},
      relation_active: {},

      temp_closed: {},
      temp_closed_untill: {},
      temp_closed_untill_date: {
        required: requiredIf(function () {
          return this.form.temp_closed_untill == true;
        }),
        // minValue: value => value < new Date().toISOString()
        minDate: function () {
          if (this.form.temp_closed_untill == true) {
            // return this.form.temp_closed_from == true
            //   ? this.form.temp_closed_untill_date >= this.form.temp_closed_from_date
            //   : this.form.temp_closed_untill_date >= new Date().toISOString()
            return this.form.temp_closed_from == true
              ? new Date(this.form.temp_closed_untill_date).getTime() >= new Date(this.form.temp_closed_from_date).getTime()
              : new Date(this.form.temp_closed_untill_date).getTime() >= new Date(new Date().setUTCHours(0,0,0,0)).getTime()
          }
          else
            return true;
        }
      },
      temp_closed_from: {},
      temp_closed_from_date: {
        required: requiredIf(function () {
          return this.temporarily_closed == "temp_closed_from";
        }),
        minDate: function () {
            return this.temporarily_closed == "temp_closed_from"
              ? new Date(this.form.temp_closed_from_date).getTime() >= new Date(new Date().setUTCHours(0,0,0,0)).getTime()
              : true;
        }
      },

      onlytakeaway: {},
      onlypickup_untill: {},
      onlypickup_untill_date: {
        required: requiredIf(function () {
          return this.form.onlypickup_untill == true;
        }),
        minDate: function () {
          if (this.form.onlypickup_untill == true) {
            // return this.form.onlypickup_from == true
            //   ? this.form.onlypickup_untill_date >= this.form.onlypickup_from_date
            //   : this.form.onlypickup_untill_date >= new Date().toISOString()
            return this.form.onlypickup_from == true
              ? new Date(this.form.onlypickup_untill_date).getTime() >= new Date(this.form.onlypickup_from_date).getTime()
              : new Date(this.form.onlypickup_untill_date).getTime() >= new Date(new Date().setUTCHours(0,0,0,0)).getTime()
          }
          else
            return true;
        }
      },
      onlypickup_from: {},
      onlypickup_from_date: {
        required: requiredIf(function () {
          return this.just_takeaway == "onlypickup_from";
        }),
        minDate: function () {
            return this.just_takeaway == "onlypickup_from"
              ? new Date(this.form.onlypickup_from_date).getTime() >= new Date(new Date().setUTCHours(0,0,0,0)).getTime()
              : true;
        }
      },

      none_active: {},
      onlyonlinepay: {},
      startdate: {},
      usetoprice: {},
      food_preparation_time: {},

      // Tab 7 (Contract)
      selectedContract: {
        required: (fileName) => {
          if(!fileName)
            return true;

          var filename_arr = fileName.split(".");
          var file_ext = filename_arr[filename_arr.length - 1];

          return ["pdf", "jpeg", "jpg", "png", "gif"].indexOf(file_ext) !== -1;
        }
      },
      upload_contract: {},
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  // Route koruması için
  beforeRouteLeave (to, from , next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  },

  created () {
    this.debouncedGetRestaurantsByVAT = _debounce(() => {
      this.getRestaurantsByVAT();
    }, 1000);

    if(this.itemId) {
      this.form = this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = this.getForm()
  },

  watch: {
    'form.email': function(newVal, oldVal) { this.form.email = newVal ? newVal.toLowerCase() : newVal },
    'form.eigenaar1_email': function(newVal, oldVal) { this.form.eigenaar1_email = newVal ? newVal.toLowerCase() : newVal },
    'form.eigenaar2_email': function(newVal, oldVal) { this.form.eigenaar2_email = newVal ? newVal.toLowerCase() : newVal },
    'form.btw_nummer': function(newVal, oldVal) {
      if (newVal && newVal.length == 10) {
        this.debouncedGetRestaurantsByVAT()
      } else {
        this.allRestaurantByVAT = []
      }
    },
    'form.countryId': function(newVal, oldVal) {
      this.autocomplete_address.setComponentRestrictions({ country: [this.country?.iso2] });
      this.autocomplete_firm_address.setComponentRestrictions({ country: [this.country?.iso2] });
    },

    // Watching address, postcode, city changes
    addressFields(newVal, oldVal) {
      // const [oldAddress, oldPostcode, oldCity] = oldVal.split('|');
      // const [newAddress, newPostcode, newCity] = newVal.split('|');

      // doSomething
      this.isCoordinateUptodate = false;
    },
    temporarily_closed(value) {
      this.form.temp_closed = value == "temp_closed";
      this.form.temp_closed_from = value == "temp_closed_from";

      if (!value) {
        this.form.temp_closed_untill = false;
      }

      if (this.form.temp_closed_from == false) {
        this.form.temp_closed_from_date = null;
      }

      // this.form.temp_closed_from_date =
      //   this.form.temp_closed_from == true ? this.form.temp_closed_from_date : null;

      // this.form.temp_closed_untill_date =
      //   this.form.temp_closed_untill == true ? this.form.temp_closed_untill_date : null;
    },
    just_takeaway(value) {
      this.form.onlytakeaway = value == "onlytakeaway";
      this.form.onlypickup_from = value == "onlypickup_from";

      if (!value) {
        this.form.onlypickup_untill = false;
      }

      if (this.form.onlypickup_from == false) {
        this.form.onlypickup_from_date = null;
      }

      // this.form.onlypickup_from_date =
      //   this.form.onlypickup_from == true ? this.form.onlypickup_from_date : null;

      // this.form.onlypickup_untill_date =
      //   this.form.onlypickup_untill == true ? this.form.onlypickup_untill_date : null;
    },
    queryTab(tab) {
      this.activeTab = tab ? parseInt(tab) : 0;
    },
    activeTab(tab) {
      if (this.queryTab != tab) {
        this.$router.push({ name: 'Edit Restaurant', query: { tab: tab } });
      }
    }
  },

  async mounted() {
    this.activeTab = this.queryTab ? parseInt(this.queryTab) : 0;
    this.getAllLanguages();


    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', (newV, oldV) => (this.unsavedChanges = true), {
      deep: true
    })
    //(end) -----


    // Google Maps API Autocomplete: Place Autocomplete Address Form
    this.autocomplete_address = new google.maps.places.Autocomplete(
      document.getElementById("verify_address"),
      {
        componentRestrictions: { country: [this.country?.iso2] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    this.autocomplete_address.addListener("place_changed", this.setPlace);

    this.autocomplete_firm_address = new google.maps.places.Autocomplete(
      document.getElementById("verify_firm_address"),
      {
        componentRestrictions: { country: [this.country?.iso2] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    this.autocomplete_firm_address.addListener("place_changed", this.setFirmPlace);
    //(end) -----


    this.$nextTick(() => {
      // DOM updated
      setTimeout(() => {
        this.quill_disabled = false;  // Disable auto focus when quill render

        // Başlangıçta 'form: {}' olduğu için 'getForm(...)'yönteminden hemen sonra
        // nesnenin (form) değiştiği bilgisi izleniyor (watch). Bu yüzden nesnedeki değişikliklerin
        // kullanıcı düzenlemeleri tarafından izlenmesi için 500ms sonra 'unsavedChanges = false' ataması yapıldı.
        this.unsavedChanges = false;
        this.isCoordinateUptodate = true;
      }, 2000);
    });
    //(end) -----
  },

  computed: {
    appTitle() { return process.env.VUE_APP_APP_TITLE },
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return this.$route.params.id },
    queryTab() { return this.$route.query.tab },
    restaurantSlug: {
      get: function () {
        return this.invoiceCount > 0
          ? this.form.restaurant_slug
          : this.slug("restaurant_name", "restaurant_slug");
      },
      set: function (newValue) { }
    },
    addressFields() {
      return `${this.form.restaurant_address}|${this.form.restaurant_postcode}|${this.form.restaurant_city}`;
    },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.countryId || this.$store.state.countryId));
    },
    contractUploadAllowed() { return true }, // return !this.form.upload_contract
    contractGenerateAllowed() { return this.contractUploadAllowed && !this.selectedContractFile },
  },

  methods: {
    slug: function (name, slug)  {
      var $slug = this.$globalFunc.slugify(this.$v.form[name].$model)
      //Aşağıdaki if yapısını v-model ile slug oluşunca validation yapmadığı için kullandım.
      //Slug alanında veri olmasına rağmen $v.form[b].$model değeri boş dönüyordu.
      if (this.$v.form[name].$dirty) {
        this.$v.form[slug].$model = $slug
        return $slug
      }
      // Bu satırı kullanmamın nedeni: Güncelleme işleminde form ilk görüntülendiğinde v-model -> slug uygulandığı için
      // veri tabanındaki kayıtlı slug alanları yerine yeni oluşturulan slug değerleri gösteriliyordu.
      // Bu durumda eğer veri tabanı üzerinda farklı bir slug değeri varsa görüntülenmiyordu.
      // Örn. (name)Türk Mutfağı -> (kayıtlı slug)turk-mutfak yerine (oluşturulan)turk-mutfagi görüntüleniyordu.
      return this.$v.form[slug].$model;
    },
    async getAllLanguages() {
      await this.$axios.get(this.$backend.SETTINGS.GET_LANGUAGES)
        .then((response) => (this.allLanguages = response.data));
    },


    // Form
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid);
      // return !(field.$invalid || field.$model === "");
    },
    validate () {
      this.$v.$touch()
    },
    reset () {
      this.form = this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.selectedImage = undefined
      this.previewImage = undefined
      this.$v.$reset()
    },
    getForm (id = null) {
      var form = {

        // Tab 0 (General Information)
        countryId: this.$store.state.countryId,
        lang: null,
        exclusive: false,
        restaurant_name: '',
        restaurant_slug: '',
        restaurant_address: null,
        restaurant_postcode: null,
        restaurant_city: null,
        resto_lat: null,
        resto_lng: null,
        restaurant_tel: null,
        restaurant_gsm: null,
        website: null,
        website_etenonline_be: null,
        email: null,
        facebookpage: null,
        slogan: null,
        note_public: null,
        restaurant_description: null,

        // Tab (Linked Type)
        restaurant_types: [],

        // Tab (Logo)
        restaurant_logo: '',

        // Tab (Opening Time)
        open_monday: '',
        open_tuesday: '',
        open_wednesday: '',
        open_thursday: '',
        open_friday: '',
        open_saturday: '',
        open_sunday: '',

        // Tab (Delivery Time)
        deliver_monday: '',
        deliver_tuesday: '',
        deliver_wednesday: '',
        deliver_thursday: '',
        deliver_friday: '',
        deliver_saturday: '',
        deliver_sunday: '',

        // Tab (ZIP Codes)
        zip_codes: [],
        delivery_distances: {
          fee_enabled: null,
          based_fee: null,
          threshold_meter: null,
          max_distance_meter: null,
          max_distance_enabled: false,
        },

        // Tab (Company Info)
        btw_nummer: null,
        firma_naam: null,
        firma_type: null,
        firma_mz_adres: null,
        firma_mz_postcode: null,
        firma_mz_city: null,
        bank_naam: null,
        bank_rekeningnummer: null,
        bank_rekeninghouder: null,
        eigenaar1_naam: null,
        eigenaar1_gsm: null,
        eigenaar1_email: null,
        eigenaar2_naam: null,
        eigenaar2_gsm: null,
        eigenaar2_email: null,
        referenties: null,

        // Tab (Settings)
        password: null,
        connectiewijze: 'box',
        boxpaymenttype: null,
        boxsn: null,
        boxsim: null,
        boxrentstartdate: null,

        type_commission: 'F',
        type_commission_ds: null,
        note_internal: null,
        menu_active: null,
        relation_active: null,

        temp_closed: null,
        temp_closed_untill: null,
        temp_closed_untill_date: null,
        temp_closed_from: null,
        temp_closed_from_date: null,

        onlytakeaway: null,
        onlypickup_untill: null,
        onlypickup_untill_date: null,
        onlypickup_from: null,
        onlypickup_from_date: null,

        none_active: true,
        onlyonlinepay: false,

        startdate: null,
        usetoprice: false,
        food_preparation_time: 30,

        // Tab (Contract)
        selectedContract: null,
        upload_contract: '',
      };

      if(id) {
        this.submitted = true

        this.$axios.get(this.$backend.RESTAURANT.SHOW.replace("{id}", this.itemId))
          .then((response) => {
            this.itemName = response.data.restaurant_name;
            this.hasdeliveryService = response.data.hasdeliveryService;
            this.hubsCount = response.data.hubs_count;

            // Tab 0 (General Information)
            form.countryId = response.data.countryId || this.$store.state.countryId;
            form.lang = response.data.lang;
            form.exclusive = response.data.exclusive;
            form.restaurant_name = response.data.restaurant_name;
            form.restaurant_slug = response.data.restaurant_slug;
            form.restaurant_address = response.data.restaurant_address;
            form.restaurant_postcode = response.data.restaurant_postcode;
            form.restaurant_city = response.data.restaurant_city;
            form.resto_lat = response.data.resto_lat;
            form.resto_lng = response.data.resto_lng;
            form.restaurant_tel = response.data.restaurant_tel;
            form.restaurant_gsm = response.data.restaurant_gsm;
            form.website = response.data.website;
            form.website_etenonline_be = response.data.website_etenonline_be;
            form.email = response.data.email;
            form.facebookpage = response.data.facebookpage;
            form.slogan = response.data.slogan;
            form.note_public = response.data.note_public;
            form.restaurant_description = response.data.restaurant_description;

            // Tab (Linked Type)
            form.restaurant_types = response.data.restaurant_types.map((t) => {
              return {
                id: t.id,
                type: t.type,
                type_image_path: t.type_image_path,
                restaurant_linked_types_count: t.restaurant_linked_types_count,
                index: t.pivot.index,
              };
            });

            // Tab (Logo)
            // form.restaurant_logo = response.data.restaurant_logo_path;
            // this.previewImage = response.data.restaurant_logo_path;

            if(response.data.restaurant_logo_path) {
              const logo = this.$globalFunc.isURL(response.data.restaurant_logo_path)
                ? response.data.restaurant_logo_path
                : process.env.VUE_APP_ORIGIN_CDN + response.data.restaurant_logo_path;

              form.restaurant_logo = logo;
              this.previewImage = logo;
            }

            // Tab (Opening Time)
            form.open_monday = response.data.open_monday;
            form.open_tuesday = response.data.open_tuesday;
            form.open_wednesday = response.data.open_wednesday;
            form.open_thursday = response.data.open_thursday;
            form.open_friday = response.data.open_friday;
            form.open_saturday = response.data.open_saturday;
            form.open_sunday = response.data.open_sunday;

            // Tab (Delivery Time)
            form.deliver_monday = response.data.deliver_monday;
            form.deliver_tuesday = response.data.deliver_tuesday;
            form.deliver_wednesday = response.data.deliver_wednesday;
            form.deliver_thursday = response.data.deliver_thursday;
            form.deliver_friday = response.data.deliver_friday;
            form.deliver_saturday = response.data.deliver_saturday;
            form.deliver_sunday = response.data.deliver_sunday;

            // Tab (ZIP Codes)
            if (response.data.zip_codes.length) {
              form.zip_codes = response.data.zip_codes;
            }
            form.delivery_distances = {
              fee_enabled: response.data.delivery_distance_fee_enabled,
              based_fee: response.data.delivery_distance_based_fee,
              threshold_meter: response.data.delivery_distance_threshold_meter,
              max_distance_meter: response.data.delivery_max_distance_meter,
              max_distance_enabled: response.data.delivery_max_distance_meter >= 1000,
            };

            // Tab (Company Info)
            this.oldBtw = form.btw_nummer = this.$globalFunc.fixVAT(response.data.btw_nummer);
            form.firma_naam = response.data.firma_naam;
            form.firma_type = response.data.firma_type;
            form.firma_mz_adres = response.data.firma_mz_adres;
            form.firma_mz_postcode = response.data.firma_mz_postcode;
            form.firma_mz_city = response.data.firma_mz_city;
            form.bank_naam = response.data.bank_naam;
            form.bank_rekeningnummer = response.data.bank_rekeningnummer;
            form.bank_rekeninghouder = response.data.bank_rekeninghouder;
            form.eigenaar1_naam = response.data.eigenaar1_naam;
            form.eigenaar1_gsm = response.data.eigenaar1_gsm;
            form.eigenaar1_email = response.data.eigenaar1_email;
            form.eigenaar2_naam = response.data.eigenaar2_naam;
            form.eigenaar2_gsm = response.data.eigenaar2_gsm;
            form.eigenaar2_email = response.data.eigenaar2_email;
            form.referenties = response.data.referenties;

            // Tab (Settings)
            form.password = response.data.password;
            form.connectiewijze = response.data.connectiewijze;
            form.boxpaymenttype = response.data.boxpaymenttype;
            form.boxsn = response.data.boxsn;
            form.boxsim = response.data.boxsim;
            form.boxrentstartdate = response.data.boxrentstartdate;

            form.type_commission = response.data.type_commission;
            form.type_commission_ds = response.data.type_commission_ds;
            form.note_internal = response.data.note_internal;
            form.menu_active = response.data.menu_active;
            form.relation_active = response.data.relation_active;

            // Temporarily Closed ?
            form.temp_closed = response.data.temp_closed;
            form.temp_closed_untill = response.data.temp_closed_untill;
            form.temp_closed_untill_date = response.data.temp_closed_untill_date;
            form.temp_closed_from = response.data.temp_closed_from;
            form.temp_closed_from_date = response.data.temp_closed_from_date;

            this.temporarily_closed
              = form.temp_closed == true ? 'temp_closed'
              : form.temp_closed_from == true ? 'temp_closed_from'
              : null;


            // Just Takeaway?
            form.onlytakeaway = response.data.onlytakeaway;
            form.onlypickup_untill = response.data.onlypickup_untill;
            form.onlypickup_untill_date = response.data.onlypickup_untill_date;
            form.onlypickup_from = response.data.onlypickup_from;
            form.onlypickup_from_date = response.data.onlypickup_from_date;

            this.just_takeaway
              = form.onlytakeaway == true ? 'onlytakeaway'
              : form.onlypickup_from == true ? 'onlypickup_from'
              : null;

            form.none_active = response.data.none_active;
            form.onlyonlinepay = response.data.onlyonlinepay;

            form.startdate = response.data.startdate;
            form.usetoprice = response.data.usetoprice;
            form.food_preparation_time = response.data.food_preparation_time;

            // Tab (Contract)
            form.upload_contract = response.data.upload_contract;
            this.formContract.place = response.data.restaurant_city;
            this.formContract.contract_lang = response.data.lang;


            // Invoice Count
            this.invoiceCount = response.data.invoices_count;

            this.submitted = false;
          })
          .catch(() => {
            this.$router.push({ name: 'Restaurants' })
          })
      }

      return form;
    },


    // Restaurant logo
    onFileChanged(files, event) {
      if(files.length > 0) {
        this.selectedImage = files[0];
        this.$v.form.restaurant_logo.$model = this.selectedImage.name;

        // Yüklenen dosya geçerli kriterleri sağlıyor mu? (validations)
        if (this.checkIfValid("restaurant_logo"))
          this.previewImage = URL.createObjectURL(this.selectedImage);
        else {
          this.selectedImage = undefined;
          this.previewImage = undefined;
          this.$v.form.restaurant_logo.$model = '';
        }
      }
    },
    async uploadLogo() {
      const formData = new FormData();
      formData.append('restaurant_logo', this.selectedImage, this.selectedImage.name);
      formData.append('restaurant_slug', this.form.restaurant_slug);

      await this.$axios.post(this.$backend.RESTAURANT.SAVE_LOGO, formData)
        .then((response) => {
          //Api'den gelen resim yolunu hidden inputa ata (response varsa valid olacak.)
          this.form.restaurant_logo = response.data.data.restaurant_logo;
          this.selectedImage = null;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
    },
    async removeLogoFromCDN() {
      await this.$axios.delete(this.$backend.RESTAURANT.REMOVE_LOGO_FROM_CDN.replace("{id}", this.itemId))
    },


    // VAT Number
    fixVat(event) {
      const fixedValue = this.$globalFunc.fixVAT(event.target.value);
      event.target.value = fixedValue;
    },
    isValidVat(vatNumber) {
      const result = this.$globalFunc.checkVAT(vatNumber, this.country.value);
      return result || !helpers.req(vatNumber) || false;
    },


    async getRestaurantsByVAT() {
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME, { params: { 'vat_number': this.form.btw_nummer } })
        .then((response) => (this.allRestaurantByVAT = response.data));
    },
    async VATvalidation() {
      return await this.$axios
        .post(this.$backend.RESTAURANT.VAT_VALIDATION, {
          country_code: this.country?.iso2.toUpperCase(),
          vat_number: this.form.btw_nummer,
        })
    },
    async getCompanyInfo() {
      this.checkSpinner = true

      await this.VATvalidation()
        .then(({data}) => {
          if (data.response) {

            const name = data.response.name.replace(/\u00a0/g, ' ');
            const address = data.response.address.replace(/\u00a0/g, ' ').trim();

            const [firmType, ...firmName] = name.includes('|')
              ? name.split('|').map(part => part.trim())
              : name.split(' ');

            this.form.firma_type = firmType;
            this.form.firma_naam = firmName.join(" ");


            // address: "Zeven Septemberlaan 7\n3550 Heusden-Zolder"
            const [firmAddress, postcodeAndCity] = address.split('\n');
            const [firmPostcode, ...firmCity] = postcodeAndCity.split(' ');

            this.form.firma_mz_adres = firmAddress;
            this.form.firma_mz_postcode = firmPostcode;
            this.form.firma_mz_city = firmCity.join(" ");

            this.$toast.success(`Valid VAT number`);
          } else {
            this.$toast.error(`Invalid VAT (BTW) number!`);
          }
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.checkSpinner = false
        });
    },


    // Google places
    setPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }

          // case "administrative_area_level_1": {
          //   console.log("state: ", component.short_name);
          //   break;
          // }

          // case "country": {
          //   console.log("country: ", component.long_name);
          //   console.log("country: ", component.short_name);
          //   break;
          // }
        }
      }

      // street_number:  25
      // route:  Sint-Willibrordusplein
      // locality:  Heusden-Zolder
      // state:  Vlaams Gewest
      // country:  Belçika
      // country:  BE
      // address1:  Sint-Willibrordusplein 25
      // postcode:  3550
      // latitude:  51.0331523
      // longitude:  5.2814117

      this.verified_address = place.formatted_address;
      this.form.resto_lat = place.geometry.location.lat();
      this.form.resto_lng = place.geometry.location.lng();

      this.form.restaurant_address = `${route} ${street_number}`;
      this.form.restaurant_postcode = postcode.replace(/\D/g, "");
      this.form.restaurant_city = locality;

      setTimeout(() => {
        this.isCoordinateUptodate = true;
      }, 100);
    },
    setFirmPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_firm_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }

          // case "administrative_area_level_1": {
          //   console.log("state: ", component.short_name);
          //   break;
          // }

          // case "country": {
          //   console.log("country: ", component.long_name);
          //   console.log("country: ", component.short_name);
          //   break;
          // }
        }
      }

      // street_number:  25
      // route:  Sint-Willibrordusplein
      // locality:  Heusden-Zolder
      // state:  Vlaams Gewest
      // country:  Belçika
      // country:  BE
      // address1:  Sint-Willibrordusplein 25
      // postcode:  3550
      // latitude:  51.0331523
      // longitude:  5.2814117

      this.verified_firm_address = place.formatted_address;

      this.form.firma_mz_adres = `${route} ${street_number}`;
      this.form.firma_mz_postcode = postcode.replace(/\D/g, "");
      this.form.firma_mz_city = locality;
    },
    async getCoordinateFromAddress() {
      let geocoder = new google.maps.Geocoder();
      let address = `${this.form.restaurant_address} ${this.form.restaurant_postcode} ${this.form.restaurant_city} BE`;
      var vm = this;

      return new Promise(function (resolve, reject) {
        geocoder.geocode({ address: address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            vm.verified_address = results[0].formatted_address;
            vm.form.resto_lat = results[0].geometry.location.lat();
            vm.form.resto_lng = results[0].geometry.location.lng();
            vm.isCoordinateUptodate = true;
            resolve('Coordinate received');
          } else {
            reject(status);
          }
        });
      });
    },


    // Copy to ..
    deliveryTimesCopyTo() {
      this.form.deliver_tuesday =
      this.form.deliver_wednesday =
      this.form.deliver_thursday =
      this.form.deliver_friday =
      this.form.deliver_saturday =
      this.form.deliver_sunday = this.form.deliver_monday;

      this.copySucceeded.deliveryTimesCopyTo = true;
      setTimeout(() => this.copySucceeded.deliveryTimesCopyTo = false, 5000);
    },
    openingTimesCopyTo() {
      this.form.open_tuesday =
      this.form.open_wednesday =
      this.form.open_thursday =
      this.form.open_friday =
      this.form.open_saturday =
      this.form.open_sunday = this.form.open_monday;

      this.copySucceeded.openingTimesCopyTo = true;
      setTimeout(() => this.copySucceeded.openingTimesCopyTo = false, 5000);
    },
    copyFromOpeningTimeToDeliveryTime() {
      this.form.deliver_monday = this.form.open_monday;
      this.form.deliver_tuesday = this.form.open_tuesday;
      this.form.deliver_wednesday = this.form.open_wednesday;
      this.form.deliver_thursday = this.form.open_thursday;
      this.form.deliver_friday = this.form.open_friday;
      this.form.deliver_saturday = this.form.open_saturday;
      this.form.deliver_sunday = this.form.open_sunday;

      this.copySucceeded.openingTime = true;
      setTimeout(() => this.copySucceeded.openingTime = false, 5000);
    },
    copyFromRestaurantAddress() {
      this.form.firma_mz_adres = this.form.restaurant_address;
      this.form.firma_mz_postcode = this.form.restaurant_postcode;
      this.form.firma_mz_city = this.form.restaurant_city;

      this.copySucceeded.restaurantAddress = true;
      setTimeout(() => this.copySucceeded.restaurantAddress = false, 5000);
    },
    copyFromRestaurantGsm() {
      this.form.eigenaar1_gsm = this.form.restaurant_gsm;

      this.copySucceeded.restaurantGsm = true;
      setTimeout(() => this.copySucceeded.restaurantGsm = false, 3000);
    },
    copyFromRestaurantEmail() {
      this.form.eigenaar1_email = this.form.email;

      this.copySucceeded.restaurantEmail = true;
      setTimeout(() => this.copySucceeded.restaurantEmail = false, 3000);
    },


    // Contract File
    async download(file_type) {
      this.submitted = true;
      const fileName = file_type === 'contract' ? this.form.upload_contract : this.form.upload_checklist;

      await this.$axios
        .post(this.$backend.RESTAURANT.DOWNLOAD_FILES.replace("{id}", this.itemId).replace("{file_type}", file_type), { "file": fileName }, { responseType: 'blob' })
        .then((response) => {

          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          // <a href="blob:http://localhost:8080/f11c5655-6941-40b3-97bd-18152eb2a13b" download="Factuur_2022_15.pdf"></a>
        })
        .catch((error) => {
          var obj = {};
          obj[error.message] = error.response.statusText;

          this.errorAlertMessage = obj;
          this.showErrorAlert = true;
        })
        .finally(() =>this.submitted = false);
    },
    onSelectedContractChanged(files, event) {
      if(files.length > 0) {
        this.selectedContractFile = files[0];
        this.$v.form.selectedContract.$model = this.selectedContractFile.name;

        // Yüklenen dosya geçerli kriterleri sağlıyor mu? (validations)
        if (!this.checkIfValid("selectedContract")) {
          this.selectedContractFile = undefined;
          // this.$v.form.selectedContract.$model = null;
        }
      }
    },
    async uploadContract() {
      const formData = new FormData(),
        fileExt = this.selectedContractFile.name.split('.').pop(),
        fileName = `contract_${this.form.restaurant_postcode}-${this.form.restaurant_slug}.${fileExt}`;

      formData.append('file', this.selectedContractFile, fileName)

      await this.$axios.post(this.$backend.RESTAURANT.UPLOAD_FILE, formData)
        .then((response) => {
          this.$v.form.upload_contract.$model = response.data.data.file;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response
          this.showErrorAlert = true
        })
        .finally(() => {
          this.selectedContractFile = undefined;
          this.$v.form.selectedContract.$model = null;
        })
    },


    // Generate contract and save the restaurant
    async submit () {
      window.scrollTo(0,0);

      // isValidOpeningTime içinde en az 1 adet false varsa
      if (Object.values(this.isValidOpeningTime).some((item) => !item)) {
        this.activeTab = 3;
        return;
      }


      // isValidDeliveryTime içinde en az 1 adet false varsa
      if (Object.values(this.isValidDeliveryTime).some((item) => !item)) {
        this.activeTab = 4;
        return;
      }


      if (!this.$refs.resZipCodes.validate()) {
        this.activeTab = 5;
        return;
      }


      // Validate if VAT (BTW) Number is entered
      var isValidVAT = !this.form.btw_nummer || (this.oldBtw == this.form.btw_nummer);
      console.log(this.oldBtw, this.form.btw_nummer);
      console.log(isValidVAT ? "isvalidavat yes" : "isvalidvat NO");
      console.log(this.isValidVat(this.form.btw_nummer) ? "function yes" : "function NO");
      (this.submitted = !isValidVAT)
        && this.isValidVat(this.form.btw_nummer)
        && await this.VATvalidation().then(({ data }) => isValidVAT = data.success);
      this.submitted = false

      if (!isValidVAT) {
        this.activeTab = 6;
        return
      }


      if (!this.isValid) {
        this.validate();
        this.activeTab = 0;
        return;
      }


      this.submitted = true
      if (this.isEdit) {
        if (this.contractGenerateAllowed) {
          await this.generateContract();
        } else {
          await this.update();
        }
      } else {
        // await this.store();
      }
    },
    async generateContract() {
      this.$i18n.locale = this.formContract.contract_lang;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        // throw new Error("Please sign the contract!");

        // Save the restaurant if no contract signed
        await this.update()
      } else {
        this.formContract.sign = data;
        await this.$refs.contract.generatePdf();
      }
    },
    async handleContractGenerationComplete(contractFileName) {
      this.form.upload_contract = contractFileName;
      await this.update()
    },

    async update() {
      try {
        // If the latitude and longitude is not up to date or null
        if (!this.isCoordinateUptodate || !this.form.resto_lat || !this.form.resto_lng) {
          await this.getCoordinateFromAddress();
        }

        if (this.selectedImage) {
          await this.uploadLogo();
        }

        if (this.selectedContractFile) {
          await this.uploadContract();
        }

        const { data } = await this.$axios.put(
          this.$backend.RESTAURANT.UPDATE.replace("{id}", this.itemId),
          this.form
        );

        this.itemName = data.restaurant_name;

        this.successAlertMessage = { itemName: this.itemName, message: `restaurant has been successfully updated.` };
        this.dismissSuccessAlert = this.dismissSecs;
        this.showErrorAlert = false;
      } catch (error) {
        this.errorAlertMessage = error.response.data
        this.showErrorAlert = true
      } finally {
        this.submitted = false
        this.unsavedChanges = false
      }
    },

    async store() {
      // If the latitude and longitude is not up to date or null
      if (!this.isCoordinateUptodate || !this.form.resto_lat || !this.form.resto_lng) {
        await this.getCoordinateFromAddress();
      }

      if (this.selectedImage) {
        await this.uploadLogo();
      }

      if (this.selectedContractFile) {
        await this.uploadContract();
      }

      await this.$axios.post(this.$backend.RESTAURANT.STORE, this.form)
        .then((response) => {
          this.itemName = response.data.restaurant_name
          this.successAlertMessage = {
            itemName: this.itemName,
            message: `restaurant has been successfully added.`
          }
          this.dismissSecs = 3
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false

          this.unsavedChanges = false

          setTimeout(() => {
            this.$router.push({ name: 'Restaurants' })
          }, 3000)
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data ?? error.response;
          this.showErrorAlert = true
        })
        .finally(() => {
          this.submitted = false
        });
    },
  }
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
}
form .nav-pills .c-icon {
  bottom: 0.1rem;
  position: relative;
}
.mt-020 {
  margin-top: 0.20rem;
}
section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>

<style>
.ql-container{
  min-height: inherit;
}
.restaurant-lang {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .restaurant-lang {
    width: 32.8%;
  }
}
input[type="radio"] + label {
  font-weight: normal;
}

.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #d8dbe0;
}

/* Vuelidate-error-extractor */
.form-errors div label::before {
  content: "●";
  margin-right: 5px;
}
.form-errors div:nth-child(1):before {
  display: block;
  content: "Validation Errors:";
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.form-errors:empty {
  display: none;
}
#signature {
  border: dotted 2px #e4e7ea;
  border-radius: 0.25rem;
}

.contract-lang-group .form-check-label {
  text-align: center;
  width: 100%;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 4px;
}
.contract-lang-group input[type=radio]:checked + .form-check-label {
  /* table-success */
  color: #0a0b18;
  background-color: #c4ebd1;
  border-color: #92daaa;
}

.email.form-group > input {
  text-transform: lowercase;
}
.email.form-group > input::-webkit-input-placeholder,
.email.form-group > input:-moz-placeholder,
.email.form-group > input::-moz-placeholder,
.email.form-group > input:-ms-input-placeholder,
.email.form-group > input::placeholder {
    text-transform: none;
}

/* Remove Number Input's Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
#PostalCodeNum::-webkit-outer-spin-button,
#PostalCodeNum::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

/* Firefox */
#PostalCodeNum {
  appearance: textfield;
  -moz-appearance: textfield;
}

.restaurant-form .form-control {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
</style>
